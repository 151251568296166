import React from 'react'
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import App from './routes'

const root = ReactDOM.createRoot(
  document.getElementById('react-root')
)

root.render(
    <Router>
      <App />
    </Router>
)

/**
 * This script provides hot module reloading in development mode.
 */
if (module.hot && process.env.NODE_ENV === 'development') {
    module.hot.accept('./routes', () => {
    const App = require('./routes').default
    root.render(App)
  })
}
