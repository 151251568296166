import React from 'react'
import { Helmet } from 'react-helmet'
import './Layout.scss'
import withRouter from '../services/withRouter'
import Topbar from '../components/topbar'
import Footer from '../components/footer'

export default WrappedComponent => {
  const ComponentWithLayout = props => {
    const { router } = props
    return (
      <div className="theme">
        <div className="bg-container">
          <div className="bg" />
        </div>
        <Helmet>
          <title>Let's Conjugate!</title>
        </Helmet>
        <Topbar router={router} />
        <div className="layout-main">
          <WrappedComponent {...props} />
        </div>
        <Footer />
      </div>
    )
  }

  class ComponentWithLayoutContainer extends React.Component {

    componentDidMount() {
      if (window && window.scrollTo) {
        window.scrollTo(0, window.screenTop)
      }
    }
    render() {
      return <ComponentWithLayout {...this.props} {...this.state} />
    }
  }

  return withRouter(ComponentWithLayoutContainer)()
}
