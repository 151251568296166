import React from 'react'
import Footer from './footer'

class Container extends React.Component {
  render() {
    return <Footer />
  }
}

export default Container
