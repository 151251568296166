import React from 'react'
const Topbar = props => {
  const { router } = props
  return (
    <div>
      <img
        alt="Let's Conjugate!"
        className="title-image"
        src="/images/title.png"
        onClick={() => {
          router.navigate('/')
        }}
      />
    </div>
  )
}

export default Topbar
