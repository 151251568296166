import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './home.scss'

const Home = props => {
  const { router } = props
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div className="buttons">
            <img
              alt="Rules"
              className="rules"
              src="/images/rules.png"
              onClick={() => {
                router.navigate('/rules')
              }}
            />
            <img
              alt="Begin"
              className="begin"
              src="/images/begin.png"
              onClick={() => {
                router.navigate('/play')
              }}
            />
            <img
              alt="Credits"
              className="credits"
              src="/images/credits.png"
              onClick={() => {
                router.navigate('/credits')
              }}
            />
          </div>
          <div className="boxcontainer">
            <img alt="Box" className="box" src="/images/box.png" />
          </div>
          <img alt="Dolphin" className="dolphin" src="/images/cete.png" />
        </Col>
      </Row>
    </Container>
  )
}

export default Home
