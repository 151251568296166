import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './notfound.scss'

const NotFound = () => {
  return (
    <Container className="not-found">
      <Row>
        <Col xs={12}>
          <h1>404</h1>
          <p>Page Not Found</p>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFound
