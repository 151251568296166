import React from 'react'
import './credits.scss'

const Credits = props => {
  return (
    <div className="creditscontainer">
      <div>
        <p>Design by Jamie Carr</p>
        <p> Code by Luna Winters </p>
      </div>
    </div>
  )
}

export default Credits
