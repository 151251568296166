import React from 'react'
import '../index.scss'
import '../App.scss'
import { Routes, Route} from 'react-router-dom'
import withLayout from '../components/withLayout'
import NotFound from './404'
import Home from './home'
import Credits from './credits'
import Play from './play'
import Rules from './rules'

export default () => {
  return (
      <Routes>
        <Route path="/" element={withLayout(Home)} exact />
        <Route path="/play" element={withLayout(Play)} />
        <Route path="/credits" element={withLayout(Credits)} />
        <Route path="/rules" element={withLayout(Rules)} />
        <Route path="/404" element={withLayout(NotFound)} />
        <Route path="*"  element={withLayout(NotFound)} />
      </Routes>
  )
}
