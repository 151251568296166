import React from 'react'
import Rules from './rules'

class Container extends React.Component {
  render() {
    return <Rules />
  }
}

export default Container
