import React from 'react'
import './play.scss'
const Play = props => {
  const { score, kana, kanji, meaning, type, failed, selectType } = props
  return (
    <div className="playcontainer">
      <div className="score">{score}</div>
      <div className="main">
        <div className="kana">{kana}</div>
        <div className="kanji">{kanji}</div>
        <div className="meaning">{meaning}</div>
      </div>
      <div className="options">
        <img
          className={failed ? (type === 'ide' ? 'correct' : 'incorrect') : ''}
          src="/images/0_ide.png"
          alt="いで"
          onClick={selectType('ide')}
        />
        <img
          className={failed ? (type === 'shite' ? 'correct' : 'incorrect') : ''}
          src="/images/1_shite.png"
          onClick={selectType('site')}
          alt="して"
        />
        <img
          className={failed ? (type === 'nde' ? 'correct' : 'incorrect') : ''}
          src="/images/2_nde.png"
          onClick={selectType('nde')}
          alt="んで"
        />
        <img
          className={failed ? (type === 'ite' ? 'correct' : 'incorrect') : ''}
          src="/images/3_ite.png"
          onClick={selectType('ite')}
          alt="いて"
        />
        <img
          className={failed ? (type === 'te' ? 'correct' : 'incorrect') : ''}
          src="/images/4_te.png"
          onClick={selectType('te')}
          alt="て"
        />
        <img
          className={failed ? (type === 'tte' ? 'correct' : 'incorrect') : ''}
          src="/images/5_tte.png"
          onClick={selectType('tte')}
          alt="って"
        />
      </div>
    </div>
  )
}

export default Play
