import React from 'react'
import Play from './play'
import Words from '../../services/words.json'

class Container extends React.Component {
  constructor(props) {
    super(props)
    const state = this.getNewState()
    this.state = state
  }

  getNewState = () => {
    const newWord = Words[Math.floor(Math.random() * Words.length)]
    const state = {
      score: this.state && this.state.score ? this.state.score : 0,
      kana: newWord['Root Word (Kana)'],
      kanji: newWord['Root Word (Kanji)'],
      meaning: newWord['Meaning'],
      type: newWord['Type'],
      failed: false
    }
    return state
  }

  selectType = type => {
    return async () => {
      if (!this.state.failed) {
        if (type === this.state.type) {
          this.setState({ score: this.state.score + 1 }, () => {
            this.setState(this.getNewState())
          })
        } else {
          this.setState({ score: 0, failed: true })
          await this.timeout(2000)
          this.setState(this.getNewState())
        }
      }
    }
  }

  timeout = ms => new Promise(res => setTimeout(res, ms))

  render() {
    return (
      <Play
        score={this.state.score}
        kana={this.state.kana}
        kanji={this.state.kanji}
        meaning={this.state.meaning}
        failed={this.state.failed}
        type={this.state.type}
        selectType={this.selectType}
      />
    )
  }
}

export default Container
