import React from 'react'
import './rules.scss'

const Rules = props => {
  return (
    <div className="rulescontainer">
      <div>
        <p>
          “ru,” “tsu,” or “u” ending replace final syllable with “tte” Noru →
          Notte
        </p>
        <p> “ku” ending replace final syllable with “ite” Kaku → Kaite</p>
        <p>
          “su” ending replace final syllable with “shite” Hanasu → Hanashite
        </p>
        <p>
          “bu,” “mu,” or “nu” ending replace final syllable with “nde” Asobu →
          Asonde
        </p>
        <p>“gu” ending replace final syllable with “ide” Oyogu → Oyoide</p>
      </div>
    </div>
  )
}

export default Rules
