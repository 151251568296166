import React from 'react'
import Topbar from './topbar'

class Container extends React.Component {
  render() {
    return <Topbar router={this.props.router} />
  }
}

export default Container
