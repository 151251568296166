import React from 'react'
import Credits from './credits'

class Container extends React.Component {
  render() {
    return <Credits />
  }
}

export default Container
