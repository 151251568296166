import React from 'react'
import NotFound from '../components/notfound'

const notfound = props => {
  return (
      <NotFound />
  )
}

export default notfound
